import thirdPartyNormalizeUrl from 'normalize-url';

export const isValidHttpUrl = (string: string): boolean => isValidUsingUrlConstructor(string);

export const isValidUsingUrlConstructor = (string: string): boolean => {
  let url;
  try {
    const normalizedUrl = thirdPartyNormalizeUrl(string, { defaultProtocol: 'https' });
    url = new URL(normalizedUrl);
  } catch (e) {
    return false;
  }
  const hostnameSplit = url.hostname.split('.');
  const isAnyHostNameComponentEmpty = hostnameSplit.find((s) => !s);
  if (hostnameSplit.length < 2 || isAnyHostNameComponentEmpty) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};
